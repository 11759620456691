import React from "react"
import Layout from "../../components/js/layout"
import SEO from "../../components/js/seo"

import "../../components/css/page/book.css"

class BookPage extends React.Component {

  state = { 
    tagsOn: [],
    tagsOpen: false 
  }

  render () {
    return (
      <Layout page="book">
        <SEO title="얄코의 TOO MUCH 친절한 깃 & 깃허브"/>
        <section className="yalcobook">
          <h1 className="yalcobook__title">
          얄코의 TOO MUCH 친절한 깃&깃허브
            <span>#투머치_깃</span>
          </h1>
          <p className="yalcobook__brief">
            <span role='img' aria-label="book">📘</span> 진짜 개발자답게 제대로 활용할 수 있도록 제대로 파는
          </p>

          <img 
          className="yalcobook__book_img" 
          src="/images/books/toomuch-git-big.png" 
          alt="투머치 친절한 깃 & 깃허브"/>

          {/* <h2 className="yalcobook__h2">책 구매하러 가기</h2> */}
          <ul className="yalcobook__store-list">
            <li>
              <a href="https://product.kyobobook.co.kr/detail/S000203382595" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 책 구매하러 가기</a>
            </li>
            {/* <li>
              <a href="https://www.yes24.com/Product/Goods/120810165" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 예스24</a>
            </li>
            <li>
              <a href="https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=321504809" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 알라딘</a>
            </li> */}
            {/* <li>
              <a href="https://hanb.link/1a77" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 한빛미디어</a>
            </li> */}
          </ul>

          <h2 className="yalcobook__h2">무료 특별판</h2>
          <div className="yalcobook__h2_sub">
            책 분량 이후의 내용들 - 깃 훅, 서브모듈, 깃허브 액션, 깃허브 블로그 만들기 등 유용한 심화 내용을 담았습니다.
          </div>
          <ul className="yalcobook__store-list">
            <li>
              <a href="https://ebook-product.kyobobook.co.kr/dig/epd/ebook/E000005349475" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 무료 특별판 받으러 가기</a>
            </li>
            {/* <li>
              <a href="https://www.yes24.com/Product/Goods/121203908" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 예스24</a>
            </li>
            <li>
              <a href="https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=322043976" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 알라딘</a>
            </li> */}
            {/* <li>
              <a href="https://hanb.link/1a77" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 한빛미디어</a>
            </li> */}
          </ul>

          <ul className="yalcobook__details">
            <li>
              <img src="/images/books/toomuch-git/01.jpg" alt="" />
            </li>
            <li>
              <img src="/images/books/toomuch-git/02.jpg" alt="" />
            </li>
            <li>
              <img src="/images/books/toomuch-git/03.jpg" alt="" />
            </li>
            <li>
              <img src="/images/books/toomuch-git/04.jpg" alt="" />
            </li>
            <li>
              <img src="/images/books/toomuch-git/05.jpg" alt="" />
            </li>
            <li>
              <img src="/images/books/toomuch-git/06.jpg" alt="" />
            </li>
          </ul>

        </section>


      </Layout>
    )
  }

}


// const ShopsPage = () => {
// }

export default BookPage